<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
    <base-material-snackbar
      v-if="arrtext.lemgth < 2"
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      {{ snackbarText }}
    </base-material-snackbar>
    <base-material-snackbar
      v-else
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      <div v-for="(msg, ind) in arrtext" :key="ind">{{ msg }}</div>
    </base-material-snackbar>
  </v-app>
</template>
<script>
import { EventBus } from "../../EventBus"
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    // DashboardCoreSettings: () => import("../dashboard/components/core/Settings"),
    DashboardCoreView: () => import("../dashboard/components/core/View")
  },

  data: () => ({
    expandOnHover: false,
    snackbarText: "",
    snackbarColor: "error",
    snackbarTimeout: 5000,
    snackbar: false,
    arrtext: []
  }),
  created() {
    this.$log("createdddddddd ", this.$store.getters.primaryColor)
    this.$i18n.locale = "en" //this.$store.getters.language
    this.$vuetify.theme.dark = this.$store.getters.darkMode
    this.$vuetify.theme.themes.dark.primary = this.$store.getters.primaryColorDark
    this.$vuetify.theme.themes.light.primary = this.$store.getters.primaryColor
    EventBus.$on("show-alert", salert => {
      //this.$log("alertttttttttttttttttttttt app", salert)
      this.snackbarColor = salert.color ? salert.color : "#2196F3"
      this.snackbarTimeout = salert.timeOut ? salert.timeOut : 5000
      this.arrtext = salert.message.split("###")
      this.snackbarText = salert.message
      this.snackbar = true
    })
  }
}
</script>
